import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "enhanced-sharing-for-confluence"
    }}>{`Enhanced Sharing for Confluence`}</h1>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`Simply install the app using UPM. You do not need to do any additional post-setup steps.`}</p>
    <p>{`Notice, that Enhanced Sharing can not capture shared pages from before it was installed.
Also consider that disabling the App will cause shared pages not being captured.`}</p>
    <h2 {...{
      "id": "admin-features"
    }}>{`Admin Features`}</h2>
    <p>{`Admins can see all shared pages from all users.
This enables you to check for unauthorized sharing outside of your network.`}</p>
    <p>{`You can find this feature towards the top left of the Confluence administration menu.`}</p>
    <Image width={'100%'} src='/public/admin1.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`Click the filter button to filter shared pages by a particular user or only display shares to email addresses`}</p>
    <Image width={'100%'} src='/public/admin2.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`Via settings menu, you can change the date format from relative dates to absolute dates.`}</p>
    <p>{`Starting with Version 1.0.8, you can also have the user's profile picture display instead of his full name.`}</p>
    <Image width={'28em'} src='/public/settings.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`Starting with Version 1.2.0, you can enable the read confirmation feature`}</p>
    <Image width={'28em'} src='/public/settings120.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <h2 {...{
      "id": "performance-considerations"
    }}>{`Performance Considerations`}</h2>
    <p>{`The new read confirmation feature adds an additional request to every page visit in your Confluence instance, when activated.`}</p>
    <p>{`To minimize the performance impact, there is a cache which you can find in the Confluence cache settings.`}</p>
    <p>{`If you have many pages, the cache utilization can be low because of many cache misses. It is then advised to increase the cache limit
according to your instance needs. For example either 10000 or 20000 pages.`}</p>
    <p>{`Keep in mind the top visited pages are left in the cache - it is not necessary to cache all pages in your instance.`}</p>
    <Image width={'100%'} src='/public/cache.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      